import { useState } from 'react';
import { FLASH_MARKUP, FLASH_MESSAGE } from "../utils/constants";
import { hashCode } from "../utils/utils";

function FlashMessage({ message_markup = FLASH_MARKUP, message = FLASH_MESSAGE }) {
    const [hidden, setHidden] = useState(false);

    return (
        <>
            {!hidden && (hashCode(message).toString() !== localStorage.getItem("flash-seen")) &&
                <div className='columns'>
                    <div className='column'>
                        <div className="notification is-primary is-light" data-testid="flashMessage">
                            <button className="delete" onClick={() => {
                                setHidden(true);
                                localStorage.setItem("flash-seen", hashCode(message));
                            }} data-testid="flash-close"></button>
                            {message_markup}
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default FlashMessage;