import Nav from './components/Nav';
import SEO from './components/SEO';

function HowToUse() {
  return (
    <div>
      <Nav />
      <SEO
        title={"How to Use HHNH2H to Vote on Your Favorite Halloween Horror Nights Haunted Houses | HHNH2H"}
        description={"Learn how to use the HHNH2H web application to vote on your favorite Halloween Horror Nights Haunted Houses. This page will walk you through how to use the website."}
      />
      <div className='container p-4'>
        <h1 className='title is-3'>How To Use</h1>
        <p className='p-3'>
          Thanks for using our service and for helping us answer the ultimate HHN question,
          what is the best house of all time? This page seeks to answer any questions you
          might have about how to use the site.
        </p>

        <h2 className='subtitle is-4'>Voting</h2>
        <p className='p-3'>
          The core of our service is voting. We use your head to head votes to determine
          the ranking of various houses.

          Voting is pretty simple. For any matchup that you've experienced both houses in
          all you have to do is click the vote button for the house you want to vote for.
        
          <img src='/howtouse/vote.png' alt='How To Vote' />

          After you click "Vote", you'll be taken to a page where you can see the results
          of the vote you just made, including the percentage breakdown of other HHNH2H 
          users. After you're satisfied, all you need to do is click the "Next Matchup"
          button to get the next matchup to vote on.

          <img src='/howtouse/recap.png' alt='Post Vote Recap' />

          After you've voted for a while, you might see the next screen telling you that
          you've already voted! HHNH2H doesn't have accounts, how do we know what you've 
          seen! Well, after every vote you make, a record of that vote gets stored in 
          your browser's local storage. That means that your voting record won't be saved
          in between your phone and PC, say. If you want to revote, you can always start
          fresh by clearing your local storage. You can find out how to do that by Googling
          "clear local storage Chrome" or whatever your browser of choice is!

          <img src='/howtouse/alreadyvoted.png' alt='You Already Voted' />
        </p>
        <h2 className='subtitle is-4'>Filters</h2>
        <p>
          You might be thinking: "If you're doing pairwise comparisons of every HHN house,
          how can I make sure that I only vote on the ones that I've been through?" You do
          that by filtering your houses! In the sidebar on desktop or popup drawer on mobile
          you can find the filters that HHNH2H offers.
        </p>
          <img src='/howtouse/filterdesktop.png' alt='Filter on Desktop' />
          <img src='/howtouse/filtermobile.png' alt='Filter on Mobile' />

        <p>
          There are two modes for filtering: Basic Mode that allows the selection of year
          ranges and Advanced Mode that allows selection of individual years. To toggle 
          Advanced Mode just click on the toggle that says "Advanced Mode". In either mode
          you can select the year range or individual years you want to see houses from.
          Note that if you select individual year ranges from Advanced Mode, you will not
          be able to then select any year ranges until you match a full year range with
          individual years in advanced mode.

          In either mode you can select which "coast" you want to see houses from: 
          Orlando, Hollywood, Singapore or Japan.
        </p>
      </div>
    </div>
  );
}

export default HowToUse;
