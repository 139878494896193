import { useEffect, useRef, useState } from 'react';
import Nav from './components/Nav';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom'
import { getBackendUrl, roundToTwoPlaces } from './utils/utils';
import Keyframes from './components/Keyframes';

import styles from './Matchup.module.scss';
import Footer from './components/Footer';
import SEO from './components/SEO';

function Matchup() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [matchup, setMatchup] = useState({});
  // As a part of strict mode, in dev (and in my case in prod?) React will
  // double call useEffect hooks which in our case will call a double API
  // call. So use a ref to keep track of whether we already fetched the 
  // matchup and don't fetch it further unless we've made a change that
  // would necessitate it.
  const shouldFetchMatchup = useRef(true);

  const { house1Id, house2Id } = useParams();

  const getMatchup = async () => {
    if (!shouldFetchMatchup.current) {
      return;
    }

    // Fetching is happening, so disable any further updates.
    shouldFetchMatchup.current = false;
    try {
      const response = await fetch(getBackendUrl() + 'matchup/' + house1Id + '/' + house2Id);
      const data = await response.json();
      setMatchup(data);
    } catch (e) {
      console.log(e);
      // Refetch on a failure
      shouldFetchMatchup.current = true;
    }
  }

  const returnToMainScreen = (e) => {
    e.preventDefault();

    if (searchParams.get("preview") === "true") {
      // TODO: Preserve hype params if they're set here.
      navigate("/hype", { replace: true });
    } else {
      const filterVals = localStorage.getItem("filterVals");
      const searchParams = (filterVals !== null ? filterVals : "");
      navigate("/" + searchParams, { replace: true });
    }
  }

  useEffect(() => {
    getMatchup();
  }, []);

  const Ranking = (props) => {
    const color = props.index === 1 ? "has-text-info" : "has-text-success";
    return (
      <div>
        {props.ranks["overall"] >= 0 && <div className='mb-5 ml-3'>
          <div className='is-capitalized is-size-5'>Ranks:</div>
          <span className='mr-3'><span className='has-text-weight-bold'>Overall:</span> <span className={color}>#{(props.ranks["overall"] + 1)}</span></span>
          <span className='mr-3'><span className='has-text-weight-bold'>Coast:</span> <span className={color}>#{(props.ranks["coast"] + 1)}</span></span>
          <span className='mr-3'><span className='has-text-weight-bold'>Year:</span> <span className={color}>#{(props.ranks["year"] + 1)}</span></span>
          <span className='mr-3'><span className='has-text-weight-bold'>Venue:</span> <span className={color}>#{(props.ranks["venue"] + 1)}</span></span>
        </div>}
        {props.ranks["overall"] < 0 && <div className='mb-5 ml-3 is-size-5'>
          Not Enough Matchups for Rankings
        </div>}
      </div>
    )
  }

  if (Object.keys(matchup).length === 0) {
    return (
      <div>
        <Nav />
      </div>
    );
  }

  let youVoted;
  let youVotedColor;
  const yourPick = searchParams.get('your_pick');
  const houseIds = [matchup["data"]["house_1"]["id"], matchup["data"]["house_2"]["id"]];
  if (yourPick && parseInt(yourPick) && houseIds.includes(parseInt(yourPick))) {
    if (parseInt(yourPick) === matchup["data"]["house_1"]["id"]) {
      youVoted = matchup["data"]["house_1"]["name"];
      youVotedColor = "info";
    } else {
      youVoted = matchup["data"]["house_2"]["name"];
      youVotedColor = "success";
    }
  }

  const winnerPct = roundToTwoPlaces(matchup["data"]["percent_winner"] * 100);
  const loserPct = roundToTwoPlaces(100.0 - winnerPct);

  const position1Color = matchup["data"]["winner"] === matchup["data"]["house_1"]["id"] ? "info" : "success";
  const position2Color = position1Color === "info" ? "success" : "info";

  // TODO: Maybe make the colors based on the house color?
  //       Could probably use imagemagick or something to fetch the most used
  //       color out of the house image.
  return (
    <div className={styles.body}>
      <Nav />
      <SEO
        title={matchup["data"]["house_1"]["name"] + ' vs ' + matchup["data"]["house_2"]["name"] + ' | Halloween Horror Night House Matchup | HHN Head2Head'}
        description={"See more details about the head to head matchup of " + matchup["data"]["house_1"]["name"] + ' vs '
                     + matchup["data"]["house_2"]["name"] + ". Check out the vote breakdown and more details about each house."}
      />
      <div className={'columns is-centered ' + styles.main}>
        <div className={'column is-four-fifths is-flex ' + styles.matchuphero}>
          <div className='is-align-self-center' style={{ width: '100%' }}>
            <Link to={"/house/" + matchup["data"]["house_1"]["id"]} className={styles.houselink}>
              <h1 className='title is-2 has-text-info mb-1'>{matchup["data"]["house_1"]["name"]}</h1>
            </Link>
            <Ranking ranks={matchup["data"]["house_1"]["ranks"]} index={1} />
            <span className='is-italic is-size-4 m-5'>vs</span>
            <Link to={"/house/" + matchup["data"]["house_2"]["id"]} className={styles.houselink}>
              <h1 className='title is-2 has-text-success mb-0'>{matchup["data"]["house_2"]["name"]}</h1>
            </Link>
            <Ranking ranks={matchup["data"]["house_2"]["ranks"]} index={2} />

            <div className='is-size-7 is-italic'>House Ratings Update Once per Day at Midnight</div>
            <Keyframes name="growWinner" from={{ width: "0%" }} to={{ width: winnerPct + "%" }} />
            <Keyframes name="growLoser" from={{ width: "0%" }} to={{ width: loserPct + "%" }} />
            <div className='my-5'>
              <div className='columns is-gapless is-mobile'>
                <div className={'has-text-white-ter	has-background-' + position1Color + ' p-2 ' + styles.bar} style={{ width: winnerPct + '%', animation: 'growWinner 2s linear' }}>{winnerPct + "%"}</div>
                {loserPct > 0.0 && <div className={'has-text-white-ter has-background-' + position2Color + ' p-2 ' + styles.bar} style={{ width: loserPct + '%', animation: 'growLoser 2s linear' }}>{loserPct + "%"}</div>}
              </div>
            </div>

            {youVoted && <p className='px-1 pb-3 has-text-weight-semibold	'>
              You Voted for <span className={'has-text-' + youVotedColor}>{youVoted}</span>
            </p>}
            <button className={'button is-primary has-background-dark ' + styles.next} onClick={returnToMainScreen}>
              Next Matchup
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Matchup;
