import { useNavigate, Link } from 'react-router-dom';
import { addMatchupToSeenString, compressOldSeenString, fireEvent, getBackendUrl, getMatchupInSeenString, getOrMakeUserHash, oldSeenStringRegex, replaceMatchupInSeenString } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faGlobe, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import styles from './HouseDisplay.module.scss';
import { COAST_ENUM_TO_COAST, COAST_ENUM_TO_NAME } from '../utils/constants';
import BlurryLoadingImage from './BlurryLoadingImage';

/**
 * Returns the JSX tag displaying a single house.
 * 
 * Props:
 *   index: Either 1 or 2 representing which house to display.
 *   matchup: JSON from the server representing the specific matchup.
 *   disabled: Whether the vote button should be disabled or not.
 *   preview: Whether to redirect to the main screen or the /hype screen.
 *   
 * @returns A JSX tag representing the house display.
 */

function HouseDisplay({ index, matchup, disabled, preview = false }) {
    const VoteButton = (p) => {
        const navigate = useNavigate();
        async function handleClick(e) {
            e.preventDefault();
            const response = await fetch(getBackendUrl() + "matchup", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "house_1": p.house_1,
                    "house_2": p.house_2,
                    "winner": p.winner_id,
                    // This can generate and save a user hash as a side effect.
                    user: getOrMakeUserHash()
                })
            });
            if (response.ok) {
                // Okay, this is a strange bug that I don't understand.
                // If you just call .sort on houses in browser, you get
                // the list sorted correctly but run in test you get it
                // reversed. So instead, since it's two elements, I'll
                // do it by hand.
                // TODO: For whatever stupid reason, Javascript does alphabetical search even with numbers
                // so probably just need to do houses.sort((a,b) => a-b)
                let houses = [p.house_1, p.house_2];
                if (houses[0] > houses[1]) {
                    houses = [houses[1], houses[0]];
                }
                const newVotedMatchup = houses[0] + "|" + houses[1] + ";" + p.winner_id;
                const currentVotedMatchups = localStorage.getItem('votedMatchups');
                if (currentVotedMatchups && currentVotedMatchups.match(oldSeenStringRegex)) {
                    localStorage.setItem("votedMatchups", compressOldSeenString(currentVotedMatchups));
                  }
                // TODO: Need to change this with our new seen string.
                if (currentVotedMatchups && getMatchupInSeenString(currentVotedMatchups, houses[0] + "|" + houses[1])) {
                    // This means that we are revoting. So just replace the current instance with the new vote.
                    // Note that that might be the same winner or a different one.
                    localStorage.setItem("votedMatchups", replaceMatchupInSeenString(currentVotedMatchups, newVotedMatchup));
                } else {
                    localStorage.setItem("votedMatchups", addMatchupToSeenString(currentVotedMatchups, newVotedMatchup));
                }
                fireEvent('vote', {'position': index});
                let returnAddress = "/matchup/" + houses[0] + "/" + houses[1] + "?your_pick=" + p.winner_id;
                if (preview) {
                    returnAddress += '&preview=true';
                }
                return navigate(returnAddress)
            }
        }

        return (
            <button className={'button has-background-dark has-text-white-ter is-fullwidth ' + styles.vote} onClick={handleClick} disabled={p.disabled}>
                Vote
            </button>
        )
    }

    const house = matchup["house_" + index];
    let imageURL = 'https://picsum.photos/640/280';
    let previewImageURL = 'https://picsum.photos/64/28';
    if (house["image_url"]) {
        imageURL = getBackendUrl() + 
                   "static/facades/" +
                   house["event"]["year"] +
                   "/" + COAST_ENUM_TO_COAST.get(house["event"]["coast"]) +
                   "/" + house["image_url"];
        const low_res_name = house["image_url"].replace(".jpg", "_low.jpg");
        previewImageURL = getBackendUrl() +
                    "static/facades/" +
                    house["event"]["year"] +
                    "/" + COAST_ENUM_TO_COAST.get(house["event"]["coast"]) +
                    "/" + low_res_name;
    }

    return (
        <>
            <div className='box'>
                <Link id='houseImageBox' style={{borderRadius: '6px'}} to={"/house/" + house["id"]} target="_blank">
                    <BlurryLoadingImage preview={previewImageURL} image={imageURL} alt={house["name"]} width='640' height='280' imageStyleClass={""} divStyleClass={""} />
                </Link>
                <Link id='houseName' to={"/house/" + house["id"]} target="_blank">
                    <h2 className={'title is-4 mx-4 mt-3 ' + styles.housetitle} data-testid={"house_" + index + "_name"}>{house["name"]}</h2>
                </Link>
                <div className={'mx-5 is-hidden-touch mb-5 ' + styles.housedescription} id='houseDescription'>
                    {house["description"].replaceAll('\\n', '\r\n')}
                </div>
                <div id='house_info' className='columns is-mobile'>
                    <div id='year' className={styles.thirtywidth + ' ' + styles.year + ' ' + styles.infobox}>
                        <span className='icon-text'>
                            <span className='icon'>
                                <FontAwesomeIcon icon={faCalendar} />
                            </span>
                            <span className='is-size-7 has-text-weight-semibold'>{house["event"]["name"].replace('Halloween Horror Nights', 'HHN')}</span>
                        </span>
                    </div>
                    <div id='coast' title={COAST_ENUM_TO_NAME.get(house["event"]["coast"])} className={'column is-one-fifth ' + styles.coast + ' ' + styles.infobox}>
                        <span className='icon-text'>
                            <span className='icon'>
                                <FontAwesomeIcon icon={faGlobe} />
                            </span>
                            <span className='is-size-7 has-text-weight-semibold'>{COAST_ENUM_TO_NAME.get(house["event"]["coast"]).substring(0, 1)}</span>
                        </span>
                    </div>
                    <div id='venue' title={house["venue"]["description"]} className={'column ' + styles.venue + ' ' + styles.infobox}>
                        <span className='icon-text'>
                            <span className='icon'>
                                <FontAwesomeIcon icon={faLocationDot} />
                            </span>
                            <span className='is-size-7 has-text-weight-semibold'>{house["venue"]["name"]}</span>
                        </span>
                        
                    </div>
                </div>
            </div>
            <VoteButton
                house_1={matchup["house_1"]["id"]}
                house_2={matchup["house_2"]["id"]}
                winner_id={house["id"]}
                disabled={disabled} />
        </>
    );
}

export default HouseDisplay;