import { Link } from 'react-router-dom';
import Nav from './components/Nav';
import SEO from './components/SEO';

function About() {
  return (
    <div>
      <Nav />
      <SEO
        title={"About Us | HHNH2H"}
        description={"Learn more about the story of how HHNH2H came to be."}
      />
      <div className='container p-4'>
        <h1 className='title is-3'>About HHN Head2Head</h1>
        <img src='/hhn1.jpg' alt='HHN Gates' />
        <p className='p-3'>
          HHN Head2Head (hhnh2h.com) exists to finally answer the question, what's the best
          HHN house that's ever existed? We seek to answer this question using a ELO based
          algorithm.
        </p>
        <p className='p-3'>
          But we need your help! To get the most accurate ratings, we need you to vote for
          your favorite houses. It's pretty simple. Just go to the&nbsp;
          <Link to={'/'}>home page</Link> and vote in each of the house pages that come up,
          no account required. Please don't vote for houses you didn't experience.
        </p>
        <h2 className='subtitle is-5'>Upcoming Improvements</h2>
        <p className='p-3'>
          This is the alpha version of the site. In the future we hope to improve with these
          being top of mind:
          <ul>
            <li>House reviews and explicit ratings</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default About;
