import React, { useEffect, useState } from 'react';
import Nav from './components/Nav';
import { getBackendUrl, getMatchupsFromNewSeenString } from './utils/utils';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './Rankings.module.scss';
import Footer from './components/Footer';
import SEO from './components/SEO';
import HouseListItem from './components/HouseListItem';

function HypeList() {
  const [houses, setHouses] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const rankByELO = (houses, matchups) => {
    const k = 20;
    const house_to_score = {};
    const house_id_to_house = {};
    const house_id_to_num_matchups = {};
    const m = getMatchupsFromNewSeenString(matchups);
    const filteredMatchups = [];
    for (let i = 0; i < m.length; i++) {
        if (m[i][0] >= 441 && m[i][1] >= 441) {
            filteredMatchups.push(m[i]);
        }
    }

    // Initial score is 1500.
    for (let i = 0; i < houses.length; i++) {
        house_to_score[houses[i]["id"]] = 1500.0;
        house_id_to_house[houses[i]["id"]] = houses[i];
        house_id_to_num_matchups[houses[i]["id"]] = 0;
    }

    for (let i = 0; i < filteredMatchups.length; i++) {
        const winner = filteredMatchups[i][0];
        const loser = filteredMatchups[i][1];
        house_id_to_num_matchups[winner] += 1;
        house_id_to_num_matchups[loser] += 1;
        const winner_initial = house_to_score[winner];
        const loser_initial = house_to_score[loser];

        let winner_exp = (loser_initial - winner_initial)/400.0;
        winner_exp = 1/((10.0**(winner_exp))+1);

        house_to_score[winner] = winner_initial + (20)*(1-winner_exp);

        let loser_exp = (loser_initial - winner_initial)/400.0;
        loser_exp = 1/((10.0**(loser_exp))+1);

        house_to_score[loser] = loser_initial + (20)*(0 - (1-loser_exp));
    }

    const items = Object.keys(house_to_score).map((key) => {
      return [key, house_to_score[key]];
    });

    items.sort((first, second) => {
      return second[1] - first[1];
    });

    const h = items.map((item) => {
      return house_id_to_house[item[0]];
    }).filter((house) => {
      return house_id_to_num_matchups[house["id"]] > 0;
    });
    
    return h;
  }

  const goToConfig = (field, value) => {
    const params = new URLSearchParams();
    params.set(field, value);
    navigate("/rankings?" + params.toString());
  };

  const loadHouses = async () => {
    setLoading(true);

    try {
      const response = await (await fetch(getBackendUrl() + 'hype')).json();
      // TODO: Check for 200 from the backend here.
      setHouses(rankByELO(response["houses"], localStorage.getItem("votedMatchups")));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadHouses();
  }, []);

  let titleString = "My 2024 HHN Hype List";
  return (
    <div className={styles.body}>
      <Nav />
      <SEO
        title={titleString + " | HHNH2H"}
        description={titleString}
      />
      <div className={'container is-fluid mb-3 ' + styles.main}>
        <h1 className='title is-1'>My HHN 2024 Hype List</h1>
        {loading && <div className='is-size-3 has-text-centered'>
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
          <br />
          Loading...
        </div>}
        {!loading && houses.length > 0 && <div>
          {houses.map((house, i) => {
            return <HouseListItem house={house} key={house["id"]} index={i} goToConfig={goToConfig} shouldShowOverall={true} page={1} shouldShowRankNumber={true} />
          })}
        </div>}
        {!loading && houses.length === 0 && <div className='mt-3'>
          <h1>Something went wrong! Try Again!</h1>
        </div>}
      </div>
      <Footer />
    </div>
  );
}

export default HypeList;
