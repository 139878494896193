import { useEffect, useState } from "react";
import { getBackendUrl } from "../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from './ReviewTeaser.module.scss';
import { Link } from "react-router-dom";

function ReviewTeaser() {
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);

    const fetchReviews = async () => {
        setLoading(true);
        try {
            const response = await fetch(getBackendUrl() + 'review');
            if (response.status !== 200) {
                setReviews([]);
                setLoading(false);
                return;
            }

            const data = await response.json();
            setReviews(data["reviews"]);
            setLoading(false);
        } catch (e) {
            setReviews([]);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchReviews();
    }, []);
    
    return (
        <section className="section my-4">
            <h2 className="is-size-4 has-text-weight-semibold">Recent Reviews</h2>
            {loading && <div className='is-size-5 has-text-centered'>
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                <br />
                Loading...
            </div>}
            {!loading && reviews.length === 0 && <div className="is-size-5 has-text-centered">No Recent Reviews</div>}
            {!loading && reviews.length > 0 && <div className="columns mt-6 mb-3">
                {reviews.map((review) => {
                    return (
                        <div className="column box m-2 is-flex is-flex-direction-column has-background-light" key={review["id"]}>
                            <div className="mb-auto">{review["content"].substring(0, 140) + (review["content"].length > 140 ? "..." : "")}</div>
                            <div className={"has-text-gray " + styles.secondarytext}>
                                Review of house: <Link to={"/house/" + review["house_id"]}>{review["house_name"]}</Link>
                            </div>
                            <div className={"has-text-grey " + styles.secondarytext}>
                                Posted at {(new Date(Date.parse(review["created_datetime"]))).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })}
                            </div>
                        </div>
                    )
                })}    
            </div>}
        </section>
    )
}

export default ReviewTeaser;