import React, { useEffect, useState } from 'react';
import Nav from './components/Nav';
import { getBackendUrl } from './utils/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './Rankings.module.scss';
import Footer from './components/Footer';
import SEO from './components/SEO';
import Pagination from './components/Pagination';
import HouseListItem from './components/HouseListItem';

function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [houses, setHouses] = useState([]);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);
  const page = searchParams.get("page") && parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1;
  const navigate = useNavigate();

  const useDebounce = (cb, delay) => {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebounceValue(cb);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [cb, delay]);
    return debounceValue;
  }

  const debouncedSearchInput = useDebounce(searchString, 250);

  // If we can't handle an incoming param, we just delete it
  const sanitizeSearchParams = () => {
    const copiedParams = new URLSearchParams(searchParams.toString());
    const nameParam = copiedParams.get("name");
    if (nameParam !== null) {
      if (nameParam.length < 1 || nameParam.length > 30) {
        copiedParams.delete("name");
      }
    }

    const pageParam = copiedParams.get("page");
    if (pageParam) {
      if (!isNaN(parseInt(pageParam))) {
        const pageParsed = parseInt(page);
        if (pageParsed < 1 || pageParsed > 30) {
          copiedParams.delete("page");
        }
      } else {
        copiedParams.delete("page");
      }
    }

    if (copiedParams.toString() !== searchParams.toString()) {
      setSearchParams(copiedParams);
    }
    return copiedParams;
  };

  const goToConfig = (field, value) => {
    const params = new URLSearchParams();
    params.set(field, value);
    // TODO: Replace this with a search page instead of rankings
    navigate("/rankings?" + params.toString());
  };

  const loadHouses = async (params) => {
    const name = params.get('name');
    if (!name || name.length < 1) {
        setHouses([]);
        return;
    }

    setLoading(true);

    let queryString = params.toString();
    try {
      const response = await (await fetch(getBackendUrl() + 'search?' + queryString)).json();
      // TODO: Check for 200 from the backend here.
      setHouses(response["houses"]);
      setCount(response["count"]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const handleSearchQueryChange = (e) => {
    setSearchString(e.target.value);

    if (e.target.value.length > 0) {
      const newSearchParams = new URLSearchParams('name=' + e.target.value);
      setSearchParams(newSearchParams);
    } else {
      setSearchParams(new URLSearchParams());
    }
  }

  useEffect(() => {
    if(searchParams.get("name")) {
      setSearchString(searchParams.get("name"))
    }
  })

  useEffect(() => {
    loadHouses(sanitizeSearchParams());
  }, [debouncedSearchInput, page]);

  let titleString = "Search for Halloween Horror Nights Houses";
  if (searchParams.get("name")) {
    titleString = ("Search Results for  '" + searchParams.get("name") + "'");
  }
  return (
    <div className={styles.body}>
      <Nav />
      <SEO
        title={titleString + " | HHNH2H"}
        description={titleString}
      />
      <div className={'container is-fluid mb-3 ' + styles.main}>
        <div className={'control is-large ' + (loading ? "is-loading" : "")}>
            <input className='input is-rounded is-large' data-testid="search" type="text" placeholder='Search Houses Here!' value={searchString} onChange={(e) => handleSearchQueryChange(e)} />
        </div>
        {loading && <div className='is-size-3 has-text-centered'>
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
          <br />
          Loading...
        </div>}
        {!loading && houses.length > 0 && <div>
          {houses.map((house, i) => {
            return <HouseListItem house={house} key={house["id"]} index={i} goToConfig={goToConfig} shouldShowOverall={true} page={page} shouldShowRankNumber={false} />
          })}
        </div>}
        {!loading && houses.length === 0 && searchParams.get("name") && <div className='mt-3'>
          <h1>No Houses Found for This Search. Try Again!</h1>
        </div>}
        {!loading && houses.length === 0 && !searchParams.get("name") && <div className='mt-3'>
          <h1>Search for Houses in the Box Above!</h1>
        </div>}
        {searchParams.get("name") && houses.length > 0 && <Pagination base_url={"/search"} search_params={searchParams} page={page} count={count} />}
      </div>
      <Footer />
    </div>
  );
}

export default Search;
