import { Helmet } from "react-helmet-async";

function SEO({title, description, name = 'HHNH2H', type = 'website'}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            {/* These are Facebook tags */}
            {/* Most of the time this is going to be website but article is also a valid type */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    )
}

export default SEO;