import { useState } from 'react';
import { arraysEqual, generateYearListFromYearQueryString, intersection } from '../utils/utils';
import { COASTS, COASTS_TO_FULL_NAMES, DEFAULT_COAST, DEFAULT_YEARS, ONE_COAST_TITLE, ONE_YEAR_RANGE_TITLE, SWITCH_TO_ADVANCED_TITLE, YEARS, YEAR_COMBOS, YEAR_NICKNAMES, YEAR_NICKNAMES_TO_TITLES, YEAR_NICKNAMES_TO_YEARS_PARSED } from '../utils/constants';
import styles from './Filters.module.scss';

/**
 * 
 * @param {object} props The props for the component
 *                  - handleFormChange - func - Updates the query string in the containing object
 *                  - searchParams - string - The search params from the overarching Vote object
 *                  - coasts - list[string] - A list of the possible values for coasts
 *                  - defaultCoasts - list[string] - A list of the default coasts
 *                  - years - list[string] - A list of the possible year values
 *                  - defaultYears - list[string] - A list of the default years, which may contain ranges
 * @returns JSX
 */
function Filters({ handleFormChange, searchParams, coasts = COASTS, defaultCoasts = DEFAULT_COAST, years = YEARS, defaultYears = DEFAULT_YEARS }) {
    const [advancedMode, setAdvancedMode] = useState(false);

    // Basically, this is which years and coasts are selected.
    const yearsParam = generateYearListFromYearQueryString((searchParams.get("year") || defaultYears.join(",")));
    const coast = (searchParams.get("coast") || defaultCoasts.join(",")).split(",");

    const isYearFilterChecked = (filterYears) => {
        return intersection(
            yearsParam,
            filterYears
        ).length > 0;
    };

    const isCoastFilterChecked = (c) => {
        return coast.includes(c);
    };

    /**
     * Determines whether the parameter year range was created with advanced mode.
     * 
     * This is true if a year falls outside of the designated ranges.
     * AKA for all year ranges, intersection of params and year check equals range or is empty.
     */
    const isAdvancedModeUsed = (filterYears) => {
        // This is a quick sanity check before we actually match ranges.
        // Since at this point the years are fully enumerated, we know that 
        // it's only possibly a combination of year ranges if it falls into the 
        // possible length combinations of the ranges. If it is in those ranges,
        // we'll need to actually check whether the ranges are there.
        if (!YEAR_COMBOS.includes(yearsParam.length)) {
            return true;
        }

        // Finally, we need to check every year range. We only need to do this for
        // partial matches to disable all. So skip it for individual years.
        if (filterYears.length > 1) {
            let allRangesExact = false;
            YEAR_NICKNAMES.forEach((nickname) => {
                const yearRangeParsed = YEAR_NICKNAMES_TO_YEARS_PARSED.get(nickname);

                const interRange = intersection(yearRangeParsed, yearsParam);
                if (interRange.length > 0 && !arraysEqual(interRange, yearRangeParsed)) {
                    allRangesExact = true;
                }
            });

            return allRangesExact;
        }

        return false;
    };

    /**
     * Determines whether the given filter is the last one checked.
     * AKA intersection of year params and year check is equal to both.
     * @param {list} filterYears The list of years that the filter is applied to.
     */
    const isYearFilterLastOne = (filterYears) => {
        const interPFYAndPY = intersection(yearsParam, filterYears);
        if (arraysEqual(interPFYAndPY, yearsParam) && arraysEqual(interPFYAndPY, filterYears)) {
            return true;
        }

        return false;
    };

    const isYearFilterDisabled = (filterYears) => {
        // The year filter should be disabled in a couple cases:
        // 1. If the given filter is the last one checked.
        // 2. If advanced mode is used.
        let shouldBeDisabled = isYearFilterLastOne(filterYears);

        return shouldBeDisabled || (isAdvancedModeUsed(filterYears) && !advancedMode);
    };

    const isCoastFilterDisabled = (c) => {
        return coast.includes(c) && coast.length < 2;
    };

    const getYearFilterTitle = (filterYears) => {
        if (isYearFilterLastOne(filterYears)) {
            return ONE_YEAR_RANGE_TITLE;
        }

        if (isAdvancedModeUsed(filterYears) && !advancedMode) {
            return SWITCH_TO_ADVANCED_TITLE;
        }
        return "";
    };

    const getCoastFilterTitle = (c) => {
        return isCoastFilterDisabled(c) ? ONE_COAST_TITLE : "";
    };

    return (
        <section className='filters'>
            {years.length > 1 && <>
                <form className={'p-2 m-1 ' + styles.yearandcoastform}>
                    <label className="label is-large">Years</label>
                    <div className='field'>
                        <input id='advancedMode' type='checkbox' name='advancedMode' className='switch is-rounded' checked={advancedMode} onChange={() => setAdvancedMode(!advancedMode)} />
                        <label htmlFor='advancedMode' title='Select Individual Years'>Advanced Mode</label>
                    </div>
                    {!advancedMode && <>
                        {YEAR_NICKNAMES.map((nickname) => {
                            return (
                                <div className='field' key={nickname}>
                                    <input type='checkbox' id={nickname} className='is-checkradio is-rtl' name={nickname} data-testid={"year-box-" + nickname} onChange={handleFormChange} checked={isYearFilterChecked(YEAR_NICKNAMES_TO_YEARS_PARSED.get(nickname))} disabled={isYearFilterDisabled(YEAR_NICKNAMES_TO_YEARS_PARSED.get(nickname))} />
                                    <label disabled={isYearFilterDisabled(YEAR_NICKNAMES_TO_YEARS_PARSED.get(nickname))} htmlFor={nickname} title={getYearFilterTitle(YEAR_NICKNAMES_TO_YEARS_PARSED.get(nickname))}>
                                        {YEAR_NICKNAMES_TO_TITLES.get(nickname)}:
                                    </label>
                                </div>
                            );
                        })}
                    </>}
                    {advancedMode && <div className={styles.yearfilter}>
                        {Array.from(new Array(34), (x, i) => i + 1991).map((year) => {
                            return (<div className='field' key={year}>
                                <input type='checkbox' id={year} onChange={handleFormChange} className='is-checkradio is-rtl' name={year} data-testid={"year-box-" + year} checked={isYearFilterChecked([year])} disabled={isYearFilterDisabled([year])} />
                                <label disabled={isYearFilterDisabled([year])} htmlFor={year} title={getYearFilterTitle([year])}>
                                    {year + ':'}
                                </label>
                            </div>)
                        })}
                    </div>}
                </form>
            </>}
            {coasts.length > 1 && <>
                <form className={'p-2 m-1 ' + styles.yearandcoastform}>
                    <label className="label is-large">Events</label>
                    {COASTS.map((c) => {
                        return (
                            <div className='field' key={c}>
                                <input type='checkbox' id={c} className='is-checkradio is-rtl' name={c} data-testid={"coast-box-" + c} onChange={handleFormChange} checked={isCoastFilterChecked(c)} disabled={isCoastFilterDisabled(c)} />
                                <label disabled={isCoastFilterDisabled(c)} htmlFor={c} title={getCoastFilterTitle(c)}>
                                    {COASTS_TO_FULL_NAMES.get(c)}:
                                </label>
                            </div>
                        )
                    })}
                </form>
            </>}
        </section>
    );
}

export default Filters;
