import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COAST_ENUM_TO_COAST, COAST_ENUM_TO_NAME, DEFAULT_NUM_PER_PAGE } from "../utils/constants";
import { convertEloTo100Ranking, getBackendUrl } from "../utils/utils";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BlurryLoadingImage from "./BlurryLoadingImage";
import styles from './HouseListItem.module.scss';

function HouseListItem(props) {
    let imageURL = 'https://picsum.photos/640/280';
    let previewImageURL = 'https://picsum.photos/64/28';
    if (props.house["image_url"]) {
        imageURL = getBackendUrl() +
            "static/facades/" +
            props.house["event"]["year"] +
            "/" + COAST_ENUM_TO_COAST.get(props.house["event"]["coast"]) +
            "/" + props.house["image_url"];
        const low_res_name = props.house["image_url"].replace(".jpg", "_low.jpg");
        previewImageURL = getBackendUrl() +
            "static/facades/" +
            props.house["event"]["year"] +
            "/" + COAST_ENUM_TO_COAST.get(props.house["event"]["coast"]) +
            "/" + low_res_name;
    }

    const houseScore = convertEloTo100Ranking(props.house["score"]);
    let houseScoreColor = '';
    if (houseScore < 26) {
        houseScoreColor = 'has-background-danger';
    } else if (houseScore < 51) {
        houseScoreColor = 'has-background-warning';
    } else if (houseScore < 76) {
        houseScoreColor = 'has-background-success';
    } else {
        houseScoreColor = 'has-background-info';
    }
    let houseScoreText = 'has-text-light';
    if (houseScore > 25 && houseScore < 51) {
        houseScoreText = 'has-text-dark';
    }

    const getScoreDirIcon = (house) => {
        const neutral = (
            <svg data-testid="neutral" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" className='ml-3 has-text-info'>
                <g>
                    <rect fill="none" fillRule="evenodd" height="24" width="24" />
                    <rect fill="currentColor" fillRule="evenodd" height="3" width="24" x="0" y="11" />
                </g>
            </svg>
        );
        // If the house is brand new, return a neutral ranking.
        if (!house["score_history"] || house.score_history.length < 1) {
            return neutral;
        }
        const weekIndex = Math.min(7, house.score_history.length) - 1;
        const weekAgoScore = house["score_history"][weekIndex]["score"];
        const todayScore = house["score"];
        // If the ranking is within 5 ELO points of its rating last week, then return a neutral.
        if (Math.abs(weekAgoScore - todayScore) < 5) {
            return neutral;
        } else if (weekAgoScore > house["score"]) {
            return (
                <FontAwesomeIcon data-testid="negative" className='ml-3 has-text-danger' icon={faChevronDown} size="xl" />
            )
        } else if (weekAgoScore < house["score"]) {
            return (
                <FontAwesomeIcon data-testid="positive" className='ml-3 has-text-success' icon={faChevronUp} size="xl" />
            )
        }

        // The equals case is already handled so just return neutral just in case there's
        // something I'm missing here.
        return neutral;
    }

    const isHousePreview = (house) => {
        return Date.parse(house["preview_until"]) > Date.now();
    };

    return (
        <div key={props.house["id"]} className='columns my-3'>
            {props.shouldShowRankNumber && <div className='column is-narrow is-size-4 has-text-weight-bold	is-align-self-center'>
                #{(props.page - 1) * DEFAULT_NUM_PER_PAGE + props.index + 1}
            </div>}
            <div className='column is-narrow'>
                <Link to={"/house/" + props.house["id"]}>
                    <div className={styles.houseimage}>
                        <BlurryLoadingImage preview={previewImageURL} image={imageURL} alt={props.house["name"]} width='640' height='280' imageStyleClass={""} divStyleClass={""} />
                        <div data-testid="scorecircle" className={styles.score + ' ' + houseScoreColor}>
                            <span className={houseScoreText}>{houseScore / 10}</span>
                            <span className={styles.scorefiller}> / 10</span>
                        </div>
                    </div>
                </Link>
            </div>
            <div className={'column ' + styles.houseinfo}>
                <div className='is-flex is-flex-direction-row mb-5'>
                    <Link to={"/house/" + props.house["id"]} className={styles.houselink}>
                        <h2 className='title is-5'>{props.house["name"]}</h2>
                    </Link>
                    {getScoreDirIcon(props.house)}
                </div>
                {!props.shouldShowOverall && !isHousePreview(props.house) && <div className='columns'>
                    <div className='column is-align-self-center'>
                        <div><span className='has-text-weight-bold'>Overall Ranking:</span> #{props.house["ranks"]["overall"] + 1}</div>
                    </div>
                </div>}
                <div className="field is-grouped is-grouped-multiline">
                    <div className='control'>
                        <div data-testid='coastPill' className='tags has-addons is-clickable' onClick={() => props.goToConfig("coast", COAST_ENUM_TO_COAST.get(props.house["event"]["coast"]))}>
                            <span className='tag'>Coast</span>
                            <span className='tag is-primary'>{COAST_ENUM_TO_NAME.get(props.house["venue"]["coast"])}</span>
                        </div>
                    </div>
                    <div className='control'>
                        <div data-testid='venuePill' className='tags has-addons is-clickable' onClick={() => props.goToConfig("venue", props.house["venue"]["id"])}>
                            <span className='tag'>Venue</span>
                            <span className='tag is-link'>{props.house["venue"]["name"]}</span>
                        </div>
                    </div>
                    <div className='control'>
                        <div data-testid='yearPill' className='tags has-addons is-clickable' onClick={() => props.goToConfig("year", props.house["event"]["year"])}>
                            <span className='tag'>Year</span>
                            <span className='tag is-danger'>{props.house["event"]["year"]}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HouseListItem;