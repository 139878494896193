import { DEV_BACKEND_URL, PROD_BACKEND_URL } from "./constants"

export const isDev =() => {
    return process.env.NODE_ENV === "development";
}

export const fireEvent = (n, args = {}) => {
    if (!isDev() && window.gtag) {
        window.gtag('event', n, args);
    }
}

export const getBackendUrl = () => {
    if (isDev()) {
        return DEV_BACKEND_URL
    }

    return PROD_BACKEND_URL
}

export const oldSeenStringRegex = /^(\d{1,3}\|\d{1,3};\d{1,3}(,*))*$/;

export const newSeenStringRegex = /^(\d{1,3}\|(\d{1,3}|,|\d{1,3}\-\d{1,3})+;*)*$/;

/**
 * Checks 
 * @param {string} seenString The formatted seen string of matchups and winners.
 * @param {string} targetStart The target matchup string minus the winner.
 * @returns 0 if the target is not in the string, null if the local stored is malformed and the matchup if it's found.
 */
// TODO: Consider switching this to binary search.
export const getMatchupInSeenString = (seenString, targetStart) => {
    if (!seenString) {
        return 0;
    }

    if (!seenString.match(oldSeenStringRegex) && !seenString.match(newSeenStringRegex)) {
        return null;
    }

    if (seenString.match(oldSeenStringRegex)) {
        const splitString = seenString.split(',');
        for (const matchup of splitString) {
            if (matchup.startsWith(targetStart)) {
                return matchup;
            }
        }
    }

    if (seenString.match(newSeenStringRegex)) {
        const matchups = getMatchupsFromNewSeenString(seenString);
        const jsonMatchups = JSON.stringify(matchups);
        const incoming = targetStart.split("|").map((e) => parseInt(e));
        const reversed = [incoming[1], incoming[0]];
        if (!jsonMatchups.includes(JSON.stringify(incoming)) && !jsonMatchups.includes(JSON.stringify(reversed))) {
            return 0;
        }

        let sorted = incoming;
        sorted.sort((a,b) => a-b);
        // Now we know that we have a match.
        // Since our matchups string is sorted with winner first, if the incoming matches the matchup then the winner is the first element.
        if (matchups.includes(incoming)) {
            return sorted.join("|") + ";" + incoming[0];
        } else {
            return sorted.join("|") + ";" + incoming[1];
        }
    }

    return 0;
}

const makeSeenMapFromSeenString = (seenString) => {
    const matchups = new Map();

    const matchupList = getMatchupsFromNewSeenString(seenString);
    for (const matchup of matchupList) {
        if (matchups.has(matchup[0])) {
            if (!matchups.get(matchup[0]).includes(matchup[1])) {
                matchups.get(matchup[0]).push(matchup[1]);
            }
        } else {
            matchups.set(matchup[0], [matchup[1]]);
        }
    }

    return matchups;
}

export const addMatchupToSeenString = (seenString, matchup) => {
    const matchups = makeSeenMapFromSeenString(seenString);

    const parsedMatchup = matchup.split(";")[0].split("|").map((item) => parseInt(item));
    const winner = parseInt(matchup.split(";")[1]);
    const loser = (winner === parsedMatchup[0]) ? parsedMatchup[1] : parsedMatchup[0];
    // Add the new matchup here.
    if (matchups.has(winner)) {
        if (!matchups.get(winner).includes(loser)) {
            matchups.set(winner, [...matchups.get(winner), loser]);
        }
    } else {
        matchups.set(winner, [loser]);
    }

    // Return the seen string.
    return buildSeenStringFromMap(matchups);
}

export const replaceMatchupInSeenString = (seenString, matchup) => {
    let matchups = makeSeenMapFromSeenString(seenString);

    const parsedMatchup = matchup.split(";")[0].split("|").map((item) => parseInt(item));
    const winner = parseInt(matchup.split(";")[1]);
    const loser = (winner === parsedMatchup[0]) ? parsedMatchup[1] : parsedMatchup[0];

    // If matchup is in matchups, replace it.
    const loserMatchups = matchups.get(loser);
    if (loserMatchups && loserMatchups.includes(winner)) {
        const replacedMatchups = loserMatchups.filter((item) => item !== winner);
        if (replacedMatchups.length < 1) {
            matchups.delete(loser);
            matchups.set(winner, [...(matchups.get(winner) || []), loser]);
        } else {
            matchups.set(loser, replacedMatchups);
            matchups.set(winner, [...(matchups.get(winner) || []), loser]);
        }
    } else {
        // Not sure how we got here, so just add it.
        return addMatchupToSeenString(seenString, matchup);
    }

    return buildSeenStringFromMap(matchups);
}

/**
 * Converts the old seen string format to the new compressed one.
 * 
 * The old format looked like this:
 *  votedMatchups: "188|204;204,117|192;117,4|167;4,4|117;4,4|116;4"
 * 
 * But the new one will look like this:
 *  votedMatchups: "4|116-117,167;204|188,117|192"
 * 
 * This gets us a good bit of space. The id before the | is the winner.
 * 
 * @param {string} oldSeenString 
 */
export const compressOldSeenString = (oldSeenString) => {
    // If we've already upgraded our string, just pass it through.
    if (!oldSeenString.match(oldSeenStringRegex)) {
        return oldSeenString;
    }

    const split = oldSeenString.split(",");
    const matchups = new Map();
    split.forEach((matchup) => {
        const [ids, winner] = matchup.split(";");
        const [id1, id2] = ids.split("|");
        if (matchups.has(winner)) {
            const matchupsForWinner = matchups.get(winner);
            matchupsForWinner.push(winner === id1 ? id2 : id1);
            matchups.set(winner, matchupsForWinner);
        } else {
            matchups.set(winner, [winner === id1 ? id2 : id1]);
        }
    });

    return buildSeenStringFromMap(matchups);
}

const buildSeenStringFromMap = (matchups) => {
    let newSeenString = [];

    for (const [key, value] of matchups.entries()) {
        const matchupInts = value.map((v) => parseInt(v));
        matchupInts.sort();
        let matchupVals = [];
        let rangeStr = "";
        let lastVal = -1;
        for (let i = 0; i < matchupInts.length; i++) {
            if((matchupInts[i] - 1) === lastVal && i !== matchupInts.length - 1 && matchupInts[i+1] === (matchupInts[i] + 1)) {
                // This is the middle value in a sequence, so skip it.
                lastVal = matchupInts[i];
                continue;
            }
            if (i !== matchupInts.length - 1 && matchupInts[i+1] === (matchupInts[i] + 1)) {
                // This is the first item in a range, so set the rangeStr to this.
                rangeStr = matchupInts[i].toString();
                lastVal = matchupInts[i];
                continue;
            }
            if((matchupInts[i] - 1) === lastVal) {
                // We know that the next value is not +1 so it's the end of the sequence
                // So add it with a -
                rangeStr += ("-" + matchupInts[i]);
                matchupVals.push(rangeStr);
                rangeStr = "";
                lastVal = matchupInts[i];
                continue;
            }

            // Finally, if this is just a value that doesn't have a preceeding one, add it to the string.
            matchupVals.push(matchupInts[i]);
            lastVal = matchupInts[i];
        }
        newSeenString.push(key + "|" + matchupVals.join(","))
    }
    newSeenString.sort((a, b) => {
        return parseInt(a.split("|")[0]) - parseInt(b.split("|")[0]);
    });
    return newSeenString.join(";");
}

export const getMatchupsFromNewSeenString = (newSeenString) => {
    if (!newSeenString || !newSeenString.match(newSeenStringRegex)) {
        return [];
    }

    const matchups = [];
    const winnerGroups = newSeenString.split(";");

    for (const group of winnerGroups) {
        const winner = parseInt(group.split("|")[0]);

        const losers = group.split("|")[1].split(",");
        for (const loserGroup of losers) {
            if (!loserGroup.includes("-")) {
                const loser = parseInt(loserGroup);
                matchups.push([winner, loser]);
            } else {
                const rangeIndices = loserGroup.split("-").map((year) => parseInt(year));
                const years = range(rangeIndices[0], rangeIndices[1]);
                for (const year of years) {
                    matchups.push([winner, year]);
                }
            }
        }
    }

    return matchups;
};

export const range = (start, end) => {
    if (start > end) {
        return null;
    }
    return Array.from(new Array((end + 1) - start), (x, i) => i + start);
}

export const generateYearListFromYearQueryString = (yearQueryString) => {
    // This isn't the best but we also pass in a nubmer directly if it's a single year.
    // TODO: Consider switching this to a typescript function and making this a hard string.
    if (typeof yearQueryString === "number") {
        return [yearQueryString];
    }

    const split = yearQueryString.replaceAll(',', '%2C').split('%2C');
    const parsedYears = new Set();
    split.forEach((y) => {
        if (y.match(/^[\d]{4}$/g)) {
            // It's just a year.
            parsedYears.add(parseInt(y));
        } else if (y.match(/^[\d]{4}-[\d]{4}$/g)) {
            // It's a range of years.
            range(parseInt(y.substring(0, 4)), parseInt(y.substring(5, 9))).forEach((x) => {
                parsedYears.add(x);
            })
        }
    });

    return Array.from(parsedYears);
}

export const intersection = (a, b) => {
    const s = new Set(b);
    return [...new Set(a)].filter(x => s.has(x));
};

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    a.sort();
    b.sort();

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export const makeYearRanges = (yearList) => {
    let years = generateYearListFromYearQueryString(yearList);
    years.sort();

    const yearsWithRanges = [];
    for (let i = 0; i < years.length; i++) {
        const start = years[i];
        while (i + 1 < years.length && years[i + 1] === (years[i] + 1)) {
            i++;
        }
        if (years[i] === start) {
            yearsWithRanges.push("" + years[i]);
        } else {
            yearsWithRanges.push(start + "-" + years[i]);
        }
    }

    return yearsWithRanges.join(",");
};

export const roundToTwoPlaces = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
};

export const convertEloTo100Ranking = (elo) => {
    const clamped = Math.min(Math.max(elo, 1000), 2000);
    return Math.round((clamped - 1000) / 10);
}

/**
 * Returns a hash code from a string.
 * Note that this is very much not secure so don't rely on this for anything important!
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const hashCode = (str) => {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export const toSorted = (arr, fn) => {
    arr.sort(fn);
    return arr;
}

// A user hash is 20 characters of randomness.
// This IS NOT cryptographically secure but that's okay in this case.
const getUserHash = () => {
    let rdmString = "";
    for( ; rdmString.length < 20; rdmString  += Math.random().toString(36).substring(2));
    return  rdmString.substring(0, 20);
}

export const getOrMakeUserHash = () => {
    let user = localStorage.getItem("user");
    if (!user || typeof user !== "string" || user.length !== 20) {
        user = getUserHash();
        localStorage.setItem("user", user);
    }

    return user;
}