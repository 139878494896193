import { useEffect, useRef, useState } from 'react';
import Nav from './Nav';
import { fireEvent, generateYearListFromYearQueryString, getBackendUrl, getMatchupInSeenString, intersection, makeYearRanges, range, arraysEqual, compressOldSeenString, oldSeenStringRegex } from '../utils/utils';
import { COASTS, DEFAULT_COAST, DEFAULT_YEARS, FLASH_MARKUP, FLASH_MESSAGE, LATEST_YEAR_FOR_HOUSES, PREVIEW_YEAR, YEARS, YEAR_NICKNAMES, YEAR_NICKNAMES_TO_YEARS } from '../utils/constants';
import { useSearchParams } from 'react-router-dom';
import Filters from './Filters';
import HouseDisplay from './HouseDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';

import styles from './Vote.module.scss';
import { faChevronDown, faChevronUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import FlashMessage from './FlashMessage';
import SEO from './SEO';

function Vote({ coasts = COASTS, defaultCoasts = DEFAULT_COAST, years = YEARS, defaultYears = DEFAULT_YEARS, isFullWidth = false, preview = false }) {
  const [matchup, setMatchup] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileFilterExpanded, setMobileFilterExpanded] = useState(false);
  const [voteAgain, setVoteAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const yearParam = searchParams.get("year");
  const coastParam = searchParams.get("coast");

  // As a part of strict mode, in dev (and in my case in prod?) React will
  // double call useEffect hooks which in our case will call a double API
  // call. So use a ref to keep track of whether we already fetched the 
  // matchup and don't fetch it further unless we've made a change that
  // would necessitate it.
  const shouldFetchMatchup = useRef(true);

  const sanitizeCoasts = (rawParam) => {
    let sanitizedCoasts = [];
    if (rawParam !== null) {
      const coastsParam = rawParam.replaceAll(",", "%2C").split("%2C");
      for (const c of coastsParam) {
        if (coasts.includes(c)) {
          sanitizedCoasts.push(c);
        }
      }
      sanitizedCoasts = (sanitizedCoasts.length > 0 ? sanitizedCoasts : defaultCoasts).join("%2C");
    } else {
      sanitizedCoasts = defaultCoasts.join("%2C");
    }

    return sanitizedCoasts;
  }

  const sanitizeYears = (rawParam) => {
    let sanitizedYears = [];
    if (rawParam !== null) {
      const paramYears = rawParam.replaceAll(",", "%2C").split("%2C");
      const voteYears = range(years[0], years[years.length - 1]);

      for (const y of paramYears) {
        if (y.match(/^[\d]{4}(-[\d]{4}){0,1}$/g)) {
          // So this is a little weird but we'll make it work. I don't necessarily want to merge all ranges at the moment
          // so instead I'll just remove the "bad" years from the ranges and put them right back into the query string to
          // keep the same behavior.
          // TODO: Do I want to merge all ranges? This is also ugly code...
          let goodYears = [];
          const ranges = generateYearListFromYearQueryString(y);
          ranges.forEach(year => {
            if (intersection([year], voteYears).length !== 0) {
              goodYears.push(year);
            }
          });
          if (goodYears.length === 1) {
            // Convert it to a string here.
            sanitizedYears.push("" + y);
          } else if (goodYears.length > 1) {
            sanitizedYears.push(goodYears[0] + "-" + goodYears[goodYears.length - 1]);
          }
        }
      }
      sanitizedYears = (sanitizedYears.length > 0 ? sanitizedYears : defaultYears).join("%2C");
    } else {
      sanitizedYears = defaultYears.join("%2C");
    }

    return sanitizedYears;
  }

  /*
    People can edit the url params directly so let's just do a sanity check
    to ensure that we expect them before they go to the server. 
  */
  const sanitizeSearchParams = () => {
    let sanitizedCoasts = [];
    let sanitizedYears = [];

    // If there's no vals in the url but one in localstorage try to parse and use those vals.
    if ((coastParam === null || coastParam === "") && (yearParam === null || yearParam === "") && preview === false && localStorage.getItem("filterVals") !== null) {
      if (years === YEARS && coasts === COASTS) {
        const rawSearchParams = new URLSearchParams(localStorage.getItem("filterVals"));
        sanitizedCoasts = sanitizeCoasts(rawSearchParams.get("coast"));
        sanitizedYears = sanitizeYears(rawSearchParams.get("year"));
      }
    }

    if ((coastParam === null || coastParam === "") && (yearParam === null || yearParam === "") && preview === true && localStorage.getItem("previewFilterVals") !== null) {
      if (years.length === 1 && years[0] === PREVIEW_YEAR && coasts === COASTS) {
        const rawSearchParams = new URLSearchParams(localStorage.getItem("previewFilterVals"));
        sanitizedCoasts = sanitizeCoasts(rawSearchParams.get("coast"));
        sanitizedYears = sanitizeYears(rawSearchParams.get("year"));
      }
    }

    // If there are no valid entries in both the year and coast params from filterVals, then give it the defaults.
    if (sanitizedCoasts.length === 0 && sanitizedYears.length === 0) {
      if (coasts.length > 1) {
        sanitizedCoasts = sanitizeCoasts(coastParam);
      } else {
        // Here we know that coasts is length 1 or 0 so just convert it to a string
        sanitizedCoasts = coasts.join("");
      }

      if (years.length > 1) {
        sanitizedYears = sanitizeYears(yearParam);
      } else {
        sanitizedYears = years.join("")
      }
    }

    const sanitizedSearchParams = new URLSearchParams("coast=" + sanitizedCoasts + "&year=" + sanitizedYears);
    // We should only set the filterVals var if this is the main vote interface.
    if (sanitizedSearchParams.toString() !== localStorage.getItem("filterVals") && coasts === COASTS && years === YEARS) {
      localStorage.setItem("filterVals", "?" + sanitizedSearchParams.toString());
      window.dispatchEvent(new Event("storage"));
    }

    if (sanitizedSearchParams.toString() !== localStorage.getItem("previewFilterVals") && coasts === COASTS && years.length === 1 && years[0] === PREVIEW_YEAR) {
      localStorage.setItem("previewFilterVals", "?" + sanitizedSearchParams.toString());
      window.dispatchEvent(new Event("storage"));
    }

    // We were seeing an issue with the coast/yearParam being , filled and our
    // parsed string had a %2C which was triggering a refetch of the API.
    // So reencode as %2C just to make sure.
    if (sanitizedCoasts !== (coastParam || "").replaceAll(",", "%2C") || sanitizedYears !== (yearParam || "").replaceAll(",", "%2C")) {
      setSearchParams(sanitizedSearchParams);
      // We've changed the parameters so refetch the matchup.
      shouldFetchMatchup.current = true;

      // We expect a rerender here so let the getMatchup know that it can skip fetching.
      return true;
    }

    return false;
  }

  const SkipButton = (props) => {
    async function handleClick(e) {
      // If we're intentionally skipping, refetch the matchup.
      shouldFetchMatchup.current = true;
      e.preventDefault();
      getMatchup();
      fireEvent('skip');
    }

    return (
      <button className={'button is-danger ' + (isFullWidth ? 'is-fullwidth' : '')} onClick={handleClick}>
        Skip Matchup
      </button>
    )
  }

  const VoteAgainButton = () => {
    async function handleClick(e) {
      e.preventDefault();
      setVoteAgain(true);
      fireEvent('voteAgain');
    }

    return (
      <button className={'button is-info ' + (isFullWidth ? 'is-fullwidth' : '')} onClick={handleClick}>
        Vote Again
      </button>
    )
  }

  const Loading = (props) => {
    return (
      <div className='card p-3 has-text-centered'>
        <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
        <br />
        <span className='is-size-4'>Loading...</span>
      </div>
    );
  }

  const getMatchup = async (shouldSkipFetch) => {
    if (!shouldFetchMatchup.current || shouldSkipFetch) {
      return;
    }
    setLoading(true);
    // The fetch has started, no more fetches should happen.
    shouldFetchMatchup.current = false;

    const sanitizedSearchParams = new URLSearchParams();
    sanitizedSearchParams.append('year', (searchParams.get('year') || defaultYears));
    sanitizedSearchParams.append('coast', (searchParams.get('coast') || defaultCoasts));

    const votedMatchups = localStorage.getItem("votedMatchups");
    if (votedMatchups && votedMatchups.match(oldSeenStringRegex)) {
      localStorage.setItem("votedMatchups", compressOldSeenString(votedMatchups));
    }

    try {
      const response = await fetch(getBackendUrl() + 'matchup',
        {
          method: "PUT",
          body: JSON.stringify({
            year: sanitizedSearchParams.get("year"),
            coast: sanitizedSearchParams.get("coast"),
            exclude: localStorage.getItem("votedMatchups"),
            preview: preview ? "true" : "false"
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
      if (response.status === 404) {
        setMatchup({});
        setLoading(false);
        shouldFetchMatchup.current = true;
        return;
      }
      const data = await response.json();
      let houses = [data["house_1"]["id"], data["house_2"]["id"]];
      if (houses[0] > houses[1]) {
        houses = [houses[1], houses[0]];
      }
      const matchupString = houses.join("|");
      const seenMatchup = getMatchupInSeenString(localStorage.getItem("votedMatchups"), matchupString);
      if (seenMatchup) {
        fireEvent('seenMatchup');
      }
      const dataWithSeen = {
        ...data,
        "seen": seenMatchup ? parseInt(seenMatchup.split(";")[1]) : false
      }
      setMatchup(dataWithSeen);
      setLoading(false);
      // After we've successfully gotten a matchup, we can get another one.
      // This is necessary to refetch on param change.
      shouldFetchMatchup.current = true;
    } catch (e) {
      console.log(e);
      // If there's an error in the fetch, let's refetch the matchup on next change.
      shouldFetchMatchup.current = true;
      setLoading(false);
    }
  }

  function updateFilters(event) {
    // Any click into the filter should cause a refetch.
    shouldFetchMatchup.current = true;
    // Here we'll make the query string.
    const years = (searchParams.get("year") || defaultYears.join(","));
    const coast = (searchParams.get("coast") || defaultCoasts.join(",")).split(",");
    const target = event.target.name;
    if (event.target.checked) {
      // Add the item into the query state
      if (target.match(/^[\d]{4}(-[\d]{4}){0,1}$/g) || YEAR_NICKNAMES.includes(target)) {
        let yearsToAdd = target;
        if (YEAR_NICKNAMES.includes(target)) {
          yearsToAdd = YEAR_NICKNAMES_TO_YEARS.get(target);
        }
        searchParams.set("year", makeYearRanges(years + "," + yearsToAdd));
      } else if (coasts.includes(event.target.name)) {
        let c = [
          ...coast,
          event.target.name
        ];
        c.sort();
        searchParams.set('coast', c);
      }
    } else {
      // Add the item into the query state
      if (target.match(/^[\d]{4}(-[\d]{4}){0,1}$/g) || YEAR_NICKNAMES.includes(target)) {
        const parsedYears = generateYearListFromYearQueryString(years);
        let yearsToRemove = target;
        if (YEAR_NICKNAMES.includes(target)) {
          yearsToRemove = YEAR_NICKNAMES_TO_YEARS.get(target);
        }
        yearsToRemove = generateYearListFromYearQueryString(yearsToRemove);
        const difference = parsedYears.filter(y => !yearsToRemove.includes(y));
        if (difference.length > 0) {
          searchParams.set('year', makeYearRanges(difference.join(",")));
        } else {
          searchParams.delete('year');
        }
      } else if (coasts.includes(event.target.name)) {
        const filteredCoast = coast.filter(c => c !== event.target.name);
        if (filteredCoast.length > 0) {
          searchParams.set('coast', filteredCoast);
        } else {
          searchParams.delete('coast');
        }
      }
    }

    setSearchParams(searchParams);
  }

  useEffect(() => {
    const shouldSkipFetch = sanitizeSearchParams();
    getMatchup(shouldSkipFetch);
  }, []);

  useEffect(() => {
    const shouldSkipFetch = sanitizeSearchParams();
    getMatchup(shouldSkipFetch);
  }, [coastParam, yearParam]);

  // TODO: Consider not using the Vote component directly so we can use the SEO tag in those components instead of here.
  return (
    <>
      {!mobileFilterExpanded && <div className={'has-background-light ' + styles.main}>
        <Nav />
        <SEO
          title={"Vote For The Best Halloween Horror Nights Haunted House | House Rankings, Reviews and Votes | HHNH2H"}
          description={"Vote for your favorite Halloween Horror Nights haunted houses and help us find the best house of all time. "
            + "Help us crown a winner and check out your favorite house to see if it makes the coveted top 20 list."
          }
        />
        <div className={'mx-3 ' + styles.content}>
          {!window.location.pathname.includes("hype") && <FlashMessage message={FLASH_MESSAGE} message_markup={FLASH_MARKUP} />}
          <div className='columns'>
            {/* This is the main vote area of the screen. Visible on all platforms. */}
            <div className='column'>
              {(Object.keys(matchup).length > 0 || loading) && <>
                {matchup["seen"] && !voteAgain && <div className='m-3'>
                  <h2 className='title is-2'>You Already Voted!</h2>
                  <p>You voted for <span className='has-text-primary is-size-4'>{matchup["seen"] === matchup["house_1"]["id"] ? matchup["house_1"]["name"] : matchup["house_2"]["name"]}</span></p>
                  <p>Change your mind and want to vote again?</p>
                  <VoteAgainButton />
                  <p>Skip to the next matchup or expand your filters to vote for all the houses you've done!</p>
                  <SkipButton />
                </div>}
                {/* Now we'll have three columns, one for vs and two for each house. */}
                <div className='columns'>
                  <div className='column'>
                    {!matchup["house_1"] && <Loading />}
                    {matchup["house_1"] && <HouseDisplay index={1} matchup={matchup} preview={arraysEqual(years, [PREVIEW_YEAR])} disabled={matchup["seen"] !== false && !voteAgain} />}
                  </div>
                  <div className='column p-0 is-one-fifth is-capitalized has-text-weight-bold is-align-items-center is-flex is-justify-content-center'>
                    <span className={styles.vs}>
                      VS
                    </span>
                  </div>
                  <div className='column'>
                    {!matchup["house_2"] && <Loading />}
                    {matchup["house_2"] && <HouseDisplay index={2} matchup={matchup} preview={arraysEqual(years, [PREVIEW_YEAR])} disabled={matchup["seen"] !== false && !voteAgain} />}
                  </div>
                </div>
              </>}
              {!loading && Object.keys(matchup).length === 0 && <>
                <div>
                  <h1 className='is-size-3 has-text-weight-bold has-text-centered'>No Houses for This Set of Filters. Try Again!</h1>
                </div>
              </>}
            </div>
            {/* This is the voting filters area of the screen. Visible on desktop+. */}
            <div className='column is-one-quarter is-hidden-touch'>
              {!matchup["seen"] && <SkipButton isFullWidth={true} />}
              <Filters coasts={coasts} defaultCoasts={defaultCoasts} years={years} defaultYears={defaultYears} handleFormChange={updateFilters} searchParams={searchParams} />
            </div>
            <div className='column is-one-fifth is-hidden-desktop'>
              {!matchup["seen"] && <SkipButton isFullWidth={true} />}
            </div>
          </div>
        </div>
        <div onClick={(e) => setMobileFilterExpanded(!mobileFilterExpanded)} className={'is-hidden-desktop has-background-info is-clickable has-text-light has-text-centered py-3 is-size-5	has-text-weight-bold ' + styles.fixedbottom}>
          Show House Filters
          <span className='icon ml-3'>
            <FontAwesomeIcon icon={faChevronUp} />
          </span>
        </div>
        <Footer />
      </div>}
      {mobileFilterExpanded && <>
        <Filters coasts={coasts} defaultCoasts={defaultCoasts} years={years} defaultYears={defaultYears} handleFormChange={updateFilters} searchParams={searchParams} />
        <div onClick={(e) => setMobileFilterExpanded(!mobileFilterExpanded)} className={'is-hidden-desktop has-background-info is-clickable has-text-light has-text-centered py-3 is-size-5	has-text-weight-bold ' + styles.fixedbottom}>
          Hide House Filters
          <span className='icon ml-3'>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </div>
      </>}
    </>
  );
}

export default Vote;
