import React, { useEffect, useState } from 'react';

const BlurryLoadingImage = ({
    preview,
    image,
    alt,
    width,
    height,
    imageStyleClass,
    divStyleClass,
    bgColor = 'transparent',
}) => {
    const [currentImage, setCurrentImage] = useState(preview);
    const [loading, setLoading] = useState(true);

    const fetchImage = (src) => {
        const loadingImage = new Image();
        loadingImage.src = src;
        loadingImage.onload = () => {
            setCurrentImage(loadingImage.src);
            setLoading(false);
        };
    };

    useEffect(() => {
        setLoading(true);
        fetchImage(image);
    }, [image]);

    useEffect(() => {
        setCurrentImage(preview);
    }, [preview]);

    return (
        <div className={divStyleClass} style={{ overflow: 'hidden' }}>
            <img
                style={{
                    filter: `${loading ? 'blur(5px)' : ''}`,
                    transition: '0.5s filter linear',
                    width: (width + 'px'),
                    background: bgColor,
                }}
                height={height}
                width={width}
                src={currentImage}
                alt={alt}
                className={imageStyleClass}
            />
        </div>
    );
};

export default BlurryLoadingImage;