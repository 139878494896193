import { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './Reviews.module.scss';
import { REVIEW_PLACEHOLDER_TEXT } from "../utils/constants";
import { getBackendUrl, getOrMakeUserHash, toSorted } from "../utils/utils";

function Reviews({ data, house_id }) {
    const [reviews, setReviews] = useState(data);
    const [pendingReview, setPendingReview] = useState("");
    const [experiencedHouse, setExperiencedHouse] = useState(true);
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(false);

    const validateReview = () => {
        // Right now the only validation we need to do is to ensure that the 
        // content isn't empty.
        return pendingReview.length > 0;
    }

    const postReview = async () => {
        // First try to validate the review. If it's not valid, set the error state.
        if (!validateReview()) {
            setFormError(true);
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(getBackendUrl() + 'review',
                {
                    method: "POST",
                    body: JSON.stringify({
                        content: pendingReview,
                        house: parseInt(house_id),
                        experienced_house: experiencedHouse,
                        // This can generate and save a user hash as a side effect.
                        user: getOrMakeUserHash()
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            if (response.status === 404) {
                setFormError(true);
            }
            if (response.status === 200) {
                const respondedReview = await response.json();
                setReviews([
                    respondedReview["review"],
                    ...reviews
                ]);
                setPendingReview("");
                setExperiencedHouse(true);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }

        setLoading(false);
    }

    const handleFormChange = (value) => {
        setPendingReview(value);
        if (formError) {
            setFormError(false);
        }
    }

    return (<div className="mx-5 my-3">
        <div className="field">
            <textarea className={"textarea mb-3" + (formError ? " is-danger" : "") + (loading ? " is-loading" : "")} placeholder={formError ? "Your review can't be empty!" : REVIEW_PLACEHOLDER_TEXT} value={pendingReview} onChange={(e) => handleFormChange(e.target.value)} disabled={loading}></textarea>
            <label className="checkbox mb-3">
                <input type="checkbox" checked={experiencedHouse} onChange={() => setExperiencedHouse(!experiencedHouse)} />
                &nbsp;I have experienced this house!
            </label>
            <div className="control">
                <button className="button is-link" disabled={loading} onClick={() => postReview()}>Post My Review</button>
            </div>
        </div>
        <div>
            {toSorted(reviews, (a, b) => {
                return new Date(b["created_datetime"]) - new Date(a["created_datetime"]);
            }).map((review) => {
                return (
                    <div className={styles.reviewrow + " " + (Boolean(review["star_user_name"] && review["star_user_name"].length > 0) ? styles.starUser : "")} key={review["id"]} data-testid={"review-" + review["id"]}>
                        {review["content"]}
                        <div className={"mt-2  " + styles.secondarytext}>
                            {review["experienced_house"] && <span className="has-text-success"><FontAwesomeIcon icon={faCheck} size="sm" /> Reviewer <strong className="has-text-success">has</strong> experienced this house.</span>}
                            {!review["experienced_house"] && <span className="has-text-danger"><FontAwesomeIcon icon={faXmark} size="sm" /> Reviewer <strong className="has-text-danger">has not</strong> experienced this house.</span>}
                        </div>
                        {review["star_user_name"] && review["star_user_name"].length > 0 && <div className="has-text-grey">
                            Reviewed by Star User:
                            <strong> <a href={review["star_user_url"]} data-testid={"review-starUser-link"} target="_blank" rel="noopener noreferrer">{review["star_user_name"]}</a></strong>
                        </div>}
                        <div className={"has-text-grey " + styles.secondarytext}>
                            Posted at {(new Date(Date.parse(review["created_datetime"]))).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })}
                        </div>
                    </div>
                );
            })}
            {reviews.length === 0 && <div>
                This house has no reviews. Be the one to leave the first!
            </div>}
        </div>
    </div>);
}

export default Reviews;