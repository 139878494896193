import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import "./styles/base.scss"
import About from './About';
import Rankings from './Rankings';
import NotFound from './NotFound';
import Matchup from './Matchup';
import HowToUse from './HowToUse';
import Vote from './components/Vote';
import Search from './Search';
import { COASTS } from './utils/constants';
import HouseDetails from './HouseDetail';
import HypeList from './HypeList';
import Admin from './Admin';

Sentry.init({
  dsn: "https://94f8c792233bdebe098ab801bf399543@o4507195767128064.ingest.us.sentry.io/4507195794063360",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/hhnh2h\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const helmetContext = {};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Vote />,
    errorElement: <NotFound />
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/howtouse",
    element: <HowToUse />
  },
  {
    path: "/rankings",
    element: <Rankings />
  },
  {
    path: "/matchup/:house1Id/:house2Id",
    element: <Matchup />
  },
  {
    path: "/house/:houseId",
    element: <HouseDetails />
  },
  {
    path: "/hype",
    element: <Vote coasts={COASTS} defaultCoasts={COASTS} years={[2024]} defaultYears={[2024]} noparams={true} preview={true} />
  },
  {
    path: "/search",
    element: <Search />
  },
  {
    path: "/hype-list",
    element: <HypeList />
  },
  {
    path: "/admin",
    element: <Admin />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
