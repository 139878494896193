import { Link } from "react-router-dom";
import { DEFAULT_NUM_PER_PAGE } from "../utils/constants";

function Pagination({ count, page, base_url, search_params, num_per_page = DEFAULT_NUM_PER_PAGE }) {
    const lastPage = Math.ceil(count / num_per_page);
    
    const makeURLForPage = (base_url, search_params, page) => {
        const searchParamsWithoutPage = new URLSearchParams(search_params.toString());
        searchParamsWithoutPage.delete("page");

        searchParamsWithoutPage.set("page", page.toString());
        const searchParams = searchParamsWithoutPage.toString();
        return base_url + (searchParams.length > 0 ? "?" : "") + searchParams;
    };

    return (
        <nav className='pagination' role='navigation' aria-label='pagination'>
            {page !== 1 && <Link to={makeURLForPage(base_url, search_params, page - 1)} className="pagination-previous">Previous page</Link>}
            {page !== lastPage && <Link to={makeURLForPage(base_url, search_params, page + 1)} className="pagination-next">Next page</Link>}
            <ul className="pagination-list">
                {page !== 1 && <li>
                    <Link to={makeURLForPage(base_url, search_params, 1)} className={"pagination-link " + (page === 1 ? "is-current" : "")} aria-label="Goto page 1">1</Link>
                </li>}
                {page >= 4 && <li>
                    <span className="pagination-ellipsis">&hellip;</span>
                </li>}
                {page >= 3 && <li>
                    <Link to={makeURLForPage(base_url, search_params, page - 1)} className="pagination-link" aria-label={"Goto page " + (page - 1)}>{page - 1}</Link>
                </li>}
                <li>
                    <Link
                        className="pagination-link is-current"
                        aria-label={"Page " + page}
                        aria-current="page"
                    >{page}</Link>
                </li>
                {page < (lastPage - 1) && <li>
                    <Link to={makeURLForPage(base_url, search_params, page + 1)} className="pagination-link" aria-label={"Goto page " + (page + 1)}>{page + 1}</Link>
                </li>}
                {page <= (lastPage - 3) && <li>
                    <span className="pagination-ellipsis">&hellip;</span>
                </li>}
                {page !== lastPage && <li>
                    <Link to={makeURLForPage(base_url, search_params, lastPage)} className={"pagination-link " + (page === lastPage ? "is-current" : "")} aria-label={"Goto page " + lastPage}>{lastPage}</Link>
                </li>}
            </ul>
        </nav>
    );
}

export default Pagination;
