import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function Nav() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [filterVal, setFilterVal] = useState("");

    useEffect(() => {
        setFilterVal(window.localStorage.getItem("filterVals"));
        window.addEventListener("storage", () => {
            setFilterVal(window.localStorage.getItem("filterVals"));
        });
    })

    return (
        <nav className='navbar' role='navigation' aria-label='main navigation'>
            <div className='navbar-brand'>
                <Link className="navbar-item has-text-weight-bold" to={'/' + (filterVal !== null ? filterVal : "")}>
                    HHN Head2Head
                </Link>

                <a className={"navbar-burger " + (isExpanded ? "is-active" : "")} role="button" aria-label="menu" aria-expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div className={'navbar-menu ' + (isExpanded ? "is-active" : "")}>
                <div className='navbar-end'>
                    <Link to={"/search"} className="navbar-item">
                        <FontAwesomeIcon className='mr-2' icon={faMagnifyingGlass} />
                        Search    
                    </Link>
                    <Link to={'/rankings'} className="navbar-item">
                        Rankings
                    </Link>
                    <Link to={'/howtouse'} className="navbar-item">
                        How To Use
                    </Link>
                    <Link to={"/about"} className='navbar-item'>
                        About
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Nav;
