import ReviewTeaser from "./ReviewTeaser";

function Footer() {
    return (
        <>
            <ReviewTeaser />
            <div style={{padding: "1rem"}}>
                HHNH2H is *not* affiliated with Universal or Halloween Horror Nights at all. For official news, check out the <a href="https://www.halloweenhorrornights.com/">official site</a>.
            </div>
        </>
    )
}

export default Footer;