import Nav from './components/Nav';

function NotFound() {

  return (
    <div>
      <Nav />
      <div>
        <h1>Not Found</h1>
      </div>
    </div>
  );
}

export default NotFound;
