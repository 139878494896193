import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getBackendUrl } from './utils/utils';

import styles from './Admin.module.scss';

const quotes = [
    "“I’ve learned from experience that if you work hard at it, and apply more energy and time to it, and more consistency, you get a better result. It comes from the work.” – Louis C.K.",
    "“In baseball, my theory is to strive for consistency, not to worry about the numbers. If you dwell on statistics you get shortsighted, if you aim for consistency, the numbers will be there at the end.” – Tom Seaver",
    "“Success isn’t always about greatness. It’s about consistency. Consistent hard work leads to success. Greatness will come.” – Dwayne Johnson"
];

/**
 * The AdminDashboard should allow me to do the following things:
 * - See the recent reviews and approve any reviews that may have been flagged by Gemini.
 * 
 */
function Admin() {
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const Review = ({ review }) => {
        const [error, setError] = useState(false);

        const approveReview = async (review_id) => {
            if (window.confirm('Are you sure you want to approve this review?')) {
                const response = await fetch(getBackendUrl() + 'admin/review/approve',
                    {
                        method: "PUT",
                        body: JSON.stringify({
                            id: review_id
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            "X-Api-Key": localStorage.getItem("api-key"),
                        },
                    });
                if (response.status !== 200) {
                    setError(true);
                } else {
                    loadAdminData();
                }
            }
        }

        const deleteReview = async (review_id) => {
            if (window.confirm('Are you sure you want to delete this review?')) {
                const response = await fetch(getBackendUrl() + 'admin/review/delete',
                    {
                        method: "DELETE",
                        body: JSON.stringify({
                            id: review_id
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            "X-Api-Key": localStorage.getItem("api-key"),
                        },
                    });
                if (response.status !== 200) {
                    setError(true);
                } else {
                    loadAdminData();
                }
                
            }
        }

        const date = new Date(review['created_datetime']);
        return (
            <div className={'card m-3 ' + (error ? styles.error : "")}>
                <div className='card-content'>
                    {error ? <div>Error</div> : <></>}
                    {review['content']}
                    <br />
                    <span className={'has-text-gray ' + styles.secondaryreviewtext}>Posted on {date.toString()} for house <Link to={'/house/' + review['house_id']}>{review['house_name']}</Link></span>
                </div>
                <footer className="card-footer">
                    <p className="card-footer-item is-paddingless">
                        <button className="button is-fullwidth has-background-success-light" onClick={() => approveReview(review['id'])}>Approve</button>
                    </p>
                    <p className="card-footer-item is-paddingless">
                        <button className="button is-fullwidth has-background-danger-light" onClick={() => deleteReview(review['id'])}>Delete</button>
                    </p>
                </footer>
            </div>
        )
    }

    const loadAdminData = async () => {
        setLoading(true);
        try {
            const response = await fetch(getBackendUrl() + "admin/dashboard", {
                headers: {
                    "X-Api-Key": localStorage.getItem("api-key"),
                }
            });
            setLoading(false);
            if (!response.ok) {
                navigate("/", { replace: true });
            }
            const data = await response.json();
            setDashboardData(data);
        } catch (e) {
            setLoading(false);
            navigate("/", { replace: true });
        }
    };

    useEffect(() => {
        loadAdminData();
    }, []);

    return (
        <>
            <div className='columns'>
                <aside className='column is-2 aside hero is-fullheight is-hidden-mobile' style={{ backgroundColor: '#354052', marginRight: '-10px' }}>
                    <div>
                        <div className='main' style={{ padding: '40px', color: '#6F7B7E' }}>
                            <div className='title' style={{ color: '#6F7B7E', fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}>General</div>
                            <Link to={"/admin"} className="item active" style={{ display: 'block', padding: '10px 0', color: '#6F7B7E' }}><span className="icon"><i className="fa fa-home"></i></span><span className="name">Dashboard</span></Link>
                        </div>
                    </div>
                </aside>
                {!loading && dashboardData && <div className="column is-10 admin-panel">
                    <section className="hero is-small">
                        <div className="hero-body">
                            <div className="container">
                                <h1 className="title">
                                    HHNH2H
                                </h1>
                                <h2 className="subtitle">
                                    Admin Page
                                </h2>
                            </div>
                        </div>

                        <div className="hero-foot">
                            <nav className="level">
                                <div className="level-item has-text-centered">
                                    <p className="heading">Total Votes</p>
                                    <p className="title">{dashboardData['all_votes']}</p>
                                </div>
                                <div className="level-item has-text-centered">
                                    <p className="heading">Votes By Me</p>
                                    <p className="title">{dashboardData['all_votes'] ? dashboardData['all_votes'] - dashboardData['other_votes'] : "?"}</p>
                                </div>
                                <div className="level-item has-text-centered">
                                    <p className="heading">Votes Excluding Me</p>
                                    <p className="title">{dashboardData['other_votes']}</p>
                                </div>
                            </nav>
                        </div>
                    </section>
                    <section className="section">
                        <div className="columns is-mobile is-multiline">
                            <div className="column is-half-desktop is-full-mobile">
                                <section className="panel">
                                    <p className="panel-heading">
                                        Recent Reviews
                                    </p>
                                    <div className="panel-block">
                                        <div id="chart5" style={{ width: '100%' }}>
                                            {dashboardData['recent_reviews'] && dashboardData['recent_reviews'].length > 0 && <div>
                                                {dashboardData['recent_reviews'].map((review) => {
                                                    return (
                                                        <Review review={review} key={review["id"]} />
                                                    )
                                                })}
                                            </div>}
                                            {(!dashboardData['recent_reviews'] || dashboardData['recent_reviews'].length === 0) && <div>No Recent Reviews</div>}
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="column is-half-desktop is-full-mobile">
                                <section className="panel">
                                    <p className="panel-heading">
                                        Review Needed
                                    </p>
                                    <div className="panel-block">
                                        <div id="chart5" style={{ height: '280px' }}>
                                            {dashboardData['review_needed'] && dashboardData['review_needed'].length > 0 && dashboardData['review_needed'].map((review) => {
                                                return (
                                                    <Review review={review} key={review["id"]} />
                                                )
                                            })}
                                            {(!dashboardData['review_needed'] || dashboardData['review_needed'].length === 0) && <div>No Recent Reviews</div>}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>}
                {loading && <p>Loading...</p>}
            </div >
            <footer className="footer">
                <div className="container">
                    <div className="has-text-centered">
                        <p>
                            {quotes[Math.floor(Math.random() * quotes.length)]}
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Admin;